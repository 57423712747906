<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem;margin-bottom: 0!important;">
		<!-- 头部标签页 -->
		<el-tabs v-model="tabIndex" @tab-click="getList">
			<el-tab-pane :label="tab.name" :key="tabI" v-for="(tab,tabI) in tabbars"></el-tab-pane>
		</el-tabs>

		<router-link :to="{name:'jf_create'}" class="mr-2">
			<el-button type="success" size="mini">发布积分商品</el-button>
		</router-link>

		<!-- 商品列表 -->
		<el-table border class="mt-3" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange" size="mini">
			<el-table-column type="selection" width="45" align="center">
			</el-table-column>
			
			<el-table-column label="商品" width="340">
				<template slot-scope="scope">
					<div class="media">
						<img class="mr-3" style="width: 70px;height: 70px;" :src="scope.row.cover">
						<div class="media-body">
							<p class="mt-0 mb-0" style="color: #ff0000;;">{{scope.row.name}}</p>
							<p class="mb-0">开始时间：{{scope.row.dhsj}}</p>
							<p class="mb-0">结束时间：{{scope.row.gqsj}}</p>
						</div>
					</div>
				</template>
			</el-table-column>
			
			<el-table-column align="center" label="类型">
				<template slot-scope="scope">
					<div class="">
						{{scope.row.lx == 0 ? '实物' : '优惠券'}}
					</div>
				</template>
			</el-table-column>
			
			<el-table-column align="center" label="商品状态">
				<template slot-scope="scope">
					<span class="badge" :class="scope.row.sfsj ? 'badge-success' : 'badge-danger'">{{scope.row.sfsj ? '上架' : '仓库'}}</span>
				</template>
			</el-table-column>
			
			<el-table-column prop="num" align="center" label="总库存"></el-table-column>
			
			<el-table-column prop="pprice" align="center" label=" 积分" width="110">
				<template slot-scope="scope">
					<span class="text-danger">￥{{scope.row.jf}}</span>
				</template>
			</el-table-column>
			
			<el-table-column align="center" label="每日限制兑换数量">
				<template slot-scope="scope">
					<div class="">
						{{scope.row.daynum == 0 ? '无限制' : scope.row.daynum}}
					</div>
				</template>
			</el-table-column>
			
			<!-- 操作 -->
			<el-table-column align="center" label="操作" width="250">
				<template slot-scope="scope">
					<div v-if="tab != 'delete'">
						<el-button type="text" size="mini" @click="navigate('jf_create',scope.row.id)">基础设置</el-button>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除商品</el-button>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<div style="height: 60px;"></div>
		<el-footer class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0;left: 200px;right: 0;z-index: 100;">
			<div style="flex: 1;" class="px-2">
				<el-pagination :current-page="page.current" :page-sizes="page.sizes" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
				 :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</el-footer>

	</div>
</template>

<script>
	import buttonSearch from '@/components/common/button-search.vue'; // 引入 按钮、搜索、高级搜索组件
	import common from '@/common/mixins/common.js'; // 引入 全局共公样式
	import { mapState } from 'vuex'; // 引入 全局 公共数据
	export default {
		mixins: [common], // 引入mixins里的common.js
		inject: ['layout'], // 依赖注入
		// 引入注册
		components: {
			buttonSearch
		},
		data() {
			return {
				preUrl: "jfsc", // api

				tabIndex: 0,
				tabbars: [
					{name: "全部",key: "all"}
				],
				form: {
					cover:'',
					name:'',
					lx:0,
					sfsj:1,
					dhsj:'',
					daynum:0,
					desc:'',
					num:0,
					jf:0,
					gqsj:'',
				},
				tableData: [],
			}
		},
		filters: {
			tree(item) {
				if (item.level == 0) {
					return item.name
				}
				let str = ''
				for (let i = 0; i < item.level; i++) {
					str += i == 0 ? '|--' : '--'
				}
				return str + ' ' + item.name;
			}
		},
		// 计算属性
		computed: {
			// 引入store-modules的user模块里面的user(存储用户相关的数据)
			...mapState({
				user: state => state.user.user,
			}),
			// 订单类型
			tab() {
				return this.tabbars[this.tabIndex].key //拿到key值，用来高级搜索
			},
			params() {
				let str = ''
				for (let key in this.form) {
					let val = this.form[key]
					if (val) {
						str += `&${key}=${this.form[key]}`
					}
				}
				return str
			}
		},
		// 生命周期，调用__getData生成的数据
		created() {

		},
		// 生命周期监听页面
		methods: {
			// 调转基础设置、商品规格、商品属性、媒体设置、商品详情页面
			navigate(name, id) {
				this.$router.push({
					name,
					params: { id }
				})
			},
			// 获取请求列表分页url
			getListUrl() {
				return `/admin/${this.preUrl}/${this.page.current}?limit=${this.page.size}&tab=${this.tab}${this.params}`
			},
			// 获取列表 可修改
			getListResult(e) {
				this.tableData = e.list
			},
			// 清空筛选条件
			clearSearch() {
				this.form = {
					title: "",
					category_id: ""
				}
			},
		},
	}
</script>

<style>
</style>
